import styled, { css, keyframes } from "styled-components"

export const Wrapper = styled.div`
  position: relative;
  margin-top: 2rem;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  @keyframes expand {
  }
  @media only screen and (min-width: 1200px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    margin: 4rem 0;
    &:hover {
      img {
        animation: expand 0.2s ease-in forwards;
      }
    }
  }
`

export const Content = styled.div`
  order: 2;
  h3 {
    color: #e2c6a9;
    font-family: "JosefinSans-Regular";
    font-weight: 400;
    margin: 0;
    line-height: 62px;
    font-size: 34px;
  }
  p {
    color: #fff;
    font-family: "JosefinSans-Regular";
    line-height: 25px;
    font-weight: 400;
  }
  @media only screen and (min-width: 1200px) {
    flex: 0 0 55%;
    order: ${({ order }) => (order === "reversed" ? 2 : 1)};
    h3 {
      font-size: 50px;
      line-height: 82px;
    }
    p {
      font-size: 18px;
      line-height: 41px;
    }
    ${props =>
      props.order === "reversed" &&
      css`
        order: 2;
      `}
  }
`

export const Image = styled.div`
  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 4px solid #1d232a;
    margin: 0 auto;
    margin-bottom: 1.5rem;
    transition: 0.25s ease;
    display: block;
    object-fit: cover;
  }
  @media only screen and (min-width: 1200px) {
    flex: 0 0 30%;
    order: ${({ order }) => (order === "reversed" ? 1 : 2)};
    img {
      width: 331px;
      height: 331px;
      border: 11px solid #1d232a;
    }
  }
`
