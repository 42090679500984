import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  @media only screen and (min-width: 1200px) {
    button {
      margin-top: 10rem;
      margin-bottom: 5rem;
    }
  }
`
