import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import WindowSizeListener from "react-window-size-listener"
import Container from "../../UI/Container"
import ServiceCard from "../../UI/ServiceCard"
import { useScrollYPosition } from "react-use-scroll-position"
import {
  Background,
  CardsInnerWrapper,
  CardsWrapper,
  Fork,
  Introduction,
  IntroductionText,
  Landing,
  Services,
  SocialMedia,
  TeamWrapper,
  Wrapper,
} from "./styles"
import epood from "../../../assets/images/epood.png"
import Team from "../../Team"
import useModal from "../../UI/Modal/useModal"
import Modal from "../../UI/Modal/Index"
import Button from "../../UI/Button"
import { useAppDispatch, useAppState } from "../../../store/context/app-context"
import PageCard from "../../UI/PageCard"
import OfferForm from "../../OfferForm"
import fb from "../../../assets/svg/fb.svg"

const Main = () => {
  const { isShowing, setIsShowing, toggle } = useModal()
  const {
    isShowing: isShowing2,
    setIsShowing: setIsShowing2,
    toggle: toggle2,
  } = useModal()
  const [loaded, setLoaded] = useState(false)
  const ref = useRef()
  const dispatch = useAppDispatch()
  const state = useAppState()
  const servicesRef = useRef()

  if (typeof document === "undefined") return null
  const handleLoaded = () => {
    setLoaded(true)
    const el = document.getElementById("landing")
    const height = el.getBoundingClientRect().height
    handleSizeChange(height)
  }

  useEffect(() => {
    const scrollFn = e => {
      if (document) {
        const el = document.getElementById("landing")
        const pos = el.getBoundingClientRect().top
        handleScroll(pos)
        console.log(pos)
      }
    }
    window.addEventListener("scroll", scrollFn)
    return () => window.removeEventListener("scroll", scrollFn)
  }, [])

  const handleScroll = pos => {
    dispatch({
      type: "SET_POSITION",
      payload: pos,
    })
  }

  const handleSizeChange = size => {
    dispatch({
      type: "SET_HEIGHT",
      payload: size,
    })
  }

  const data = useStaticQuery(graphql`
    query {
      allWpPage {
        nodes {
          link
          isFrontPage
          title
          excerpt
          id
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
      allWpService {
        nodes {
          id
          title
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  `)

  let i = 0
  return (
    <Wrapper>
      <Background />
      <Fork />
      <Container>
        <Landing>
          <h2>
            Paneme sinu restorani <br />
            <span>kuu ajaga tööle! </span>
          </h2>
          <h4>
            Menüü loomine. Peakoka teenus. Restoranide nõustamine. Catering.
          </h4>
          <SocialMedia>
            <a href="https://www.facebook.com/maitsvadmaitsed" target="blank">
              <img src={fb} alt="fb" />
            </a>
          </SocialMedia>
        </Landing>
      </Container>
      <Services id="landing" ref={servicesRef} onLoad={handleLoaded}>
        <Background />
        <WindowSizeListener
          onResize={() => {
            let el = document.getElementById("landing")
            let size = el.getBoundingClientRect().height
            handleSizeChange(size)
          }}
        >
          <Container>
            {data.allWpPage.nodes.map(node => {
              if (!node.isFrontPage) {
                i++
                return (
                  <PageCard
                    key={node.id}
                    title={node.title}
                    content={node.excerpt}
                    image={node.featuredImage.node.sourceUrl}
                    margin={i === 2 ? "top" : "bottom"}
                    order={i % 2 !== 0 ? "reverse" : null}
                    width={i === 2 ? 1 : null}
                    link={node.link}
                    shape={i === 1 ? "circle" : "square"}
                  />
                )
              }
            })}
            <PageCard
              unfinished
              title="E-Pood"
              image={epood}
              margin="top"
              order="reverse"
            />
          </Container>
        </WindowSizeListener>
      </Services>
      <Introduction>
        <Container>
          <IntroductionText>
            <h2>Ettevõtte tutvustus</h2>
            <p>
              4 kokka, 50 restorani Eestis ja välismaal ning 100 aasta jagu
              praktilist nõu! Kokkamine ja restoraniäri on lihtsalt meie kirg.
              Jagame teadmist, kuidas asju mitte teha ja vastupidi. Nõustame
              alustavaid ja tegutsevaid restorane. Loome uue menüü, pakume
              peakokateenust, teeme restardi kogu restoranile. Ära ei ütle ka
              eksklusiivsest catering´ist. Võit nii kuludes, tuludes kui heas
              maitses! <button onClick={toggle2}>Võta ühendust ></button>
            </p>
          </IntroductionText>
        </Container>
      </Introduction>
      <Modal
        setIsShowing={setIsShowing2}
        isShowing={isShowing2}
        toggle={toggle2}
        ref={ref}
        background="light"
      >
        <OfferForm />
      </Modal>
      <CardsWrapper>
        <Container>
          <CardsInnerWrapper>
            {data.allWpService.nodes.map(node => {
              return (
                <ServiceCard
                  key={node.id}
                  title={node.title}
                  image={node.featuredImage.node.sourceUrl}
                />
              )
            })}
          </CardsInnerWrapper>
        </Container>
      </CardsWrapper>
      <TeamWrapper>
        <Background bottom />
        <Container>
          <Team />
          <Button
            onClick={toggle}
            title="Võta ühendust"
            size="large"
            color="light"
            centered
          />
          <Modal
            setIsShowing={setIsShowing}
            isShowing={isShowing}
            toggle={toggle}
            ref={ref}
            background="light"
          >
            <OfferForm />
          </Modal>
        </Container>
      </TeamWrapper>
    </Wrapper>
  )
}

export default Main
