import React from "react"
import { Content, Image, Wrapper } from "./styles"

const TeamMember = props => {
  const { title, content, image, order } = props || {}

  return (
    <Wrapper>
      <Content order={order}>
        <h3>{title}</h3>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Content>
      <Image order={order}>
        <img src={image} alt={title} />
      </Image>
    </Wrapper>
  )
}

export default TeamMember
