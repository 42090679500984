import React from "react"
import { Title, Wrapper } from "./styles"

const ServiceCard = props => {
  const { title, image } = props || {}
  return (
    <Wrapper>
      <img src={image} />
      <Title>
        <h3>{title}</h3>
      </Title>
    </Wrapper>
  )
}

export default ServiceCard
