import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  z-index: 1;
  margin-top: 1rem;
  margin-bottom: 3rem;
  @media only screen and (min-width: 1200px) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 1rem 0;
  }
`
export const Content = styled.div`
  position: relative;
  z-index: 2;
  h3 {
    color: #e2c6a9;
    z-index: 2;
    font-family: "JosefinSans-Bold";
    font-weight: 700;
    text-transform: uppercase;
    font-size: 34px;
  }
  p {
    color: #ffffff;
    font-family: "JosefinSans-Regular";
    z-index: 2;
    line-height: 21px;
  }
  a {
    color: #777777;
    text-decoration: none;
    font-family: "VarelaRound-Regular";
    text-align: right;
    display: block;
  }
  ${props =>
    props.unfinished &&
    css`
      h3 {
        color: #ffffff;
        opacity: 0.41;
      }
    `}
  @media only screen and (min-width: 1200px) {
    flex: 0 0 45%;
    margin-top: 2rem;
    margin-left: 250px;
    h3 {
      font-size: 80px;
      margin-bottom: 1rem;
    }
    p {
      font-size: 18px;
      line-height: 35px;
      max-width: 600px;
      margin-bottom: 0;
    }
    a {
      margin-top: 3rem;
      transition: 0.25s ease;
      &:hover {
        color: #e2c6a9;
      }
    }
    ${props =>
      props.width &&
      css`
        p {
          max-width: 460px;
          font-size: 16px;
        }
        a {
          text-align: left;
        }
      `}
    ${props =>
      props.shape === "square" &&
      css`
        &:after {
          content: "";
          position: absolute;
          bottom: -25px;
          right: 220px;
          width: 89px;
          height: 89px;
          border: 1px solid #e2c6a9;
        }
      `}
    ${props =>
      props.shape === "circle" &&
      css`
        &:after {
          content: "";
          position: absolute;
          bottom: -25px;
          left: 25px;
          width: 77px;
          height: 77px;
          border: 1px solid #e2c6a9;
          border-radius: 50%;
        }
      `}
  }
  @media only screen and (min-width: 1400px) {
    margin-left: 450px;
  }
  ${props =>
    props.order === "reverse" &&
    css`
      @media only screen and (min-width: 1200px) {
        order: 2;
        margin-right: unset;
        margin-left: -65px;
      }
    `}
  ${props =>
    props.unfinished &&
    css`
      h4 {
        font-family: "JosefinSans-Regular";
        padding: 0.5rem 1.25rem 0.3rem 1rem;
        background: rgba(71, 71, 71, 0.89);
        font-weight: 600;
        width: min-content;
        color: #fff;
        text-transform: uppercase;
        transform: rotate(-10deg);
        text-align: right;
        margin-left: 70px;
        margin-top: -15px;
      }
      h3 {
        margin-bottom: 0;
      }
      @media only screen and (min-width: 1200px) {
        h3 {
        }
        h4 {
          font-size: 33px;
          margin-left: 5rem;
          margin-top: 2.5rem;
        }
      }
    `}
`

export const ImageWrapper = styled.div`
  z-index: 1;
  display: none;
  img {
    margin: 0;
    display: flex;
  }
  @media only screen and (min-width: 1200px) {
    display: block;
    flex: 0 0 510px;
    margin-left: -235px;
    img {
      width: 100%;
      max-width: 510px;
      max-height: 780px;
      object-fit: cover;
    }
    @media only screen and (min-width: 1400px) {
      margin-left: -270px;
    }
    ${props =>
      props.order === "reverse" &&
      css`
        @media only screen and (min-width: 1200px) {
          margin-left: 0;
        }
      `}
  }
`
