import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import TeamMember from "../UI/TeamMember"
import { Wrapper } from "./styles"

const Team = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpTeam {
        nodes {
          title
          content
          id
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  `)

  let i = 0

  return (
    <Wrapper>
      {data.allWpTeam.nodes.map(node => {
        i++
        return (
          <TeamMember
            key={node.id}
            title={node.title}
            content={node.content}
            image={node.featuredImage.node.sourceUrl}
            order={i % 2 === 0 ? "reversed" : null}
          />
        )
      })}
    </Wrapper>
  )
}

export default Team
