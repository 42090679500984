import React from "react"
import { Content, ImageWrapper, Wrapper } from "./styles"
import Truncate from "react-truncate-html"

const PageCard = props => {
  const {
    title,
    content,
    link,
    margin,
    image,
    unfinished,
    order,
    width,
    shape,
  } = props || {}

  return (
    <Wrapper margin={margin}>
      <Content
        width={width}
        unfinished={unfinished}
        order={order}
        shape={shape}
      >
        <h3>{title}</h3>
        {!unfinished ? (
          <>
            <Truncate
              lines={5}
              portrait={6}
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <a href={link}>Loe edasi ></a>
          </>
        ) : (
          <h4>Tulekul</h4>
        )}
      </Content>
      <ImageWrapper order={order}>
        <img src={image} alt={title} />
      </ImageWrapper>
    </Wrapper>
  )
}

export default PageCard
