import styled, { css } from "styled-components"
import shapes from "../../../assets/svg/bg-shapes.svg"
import fork from "../../../assets/images/kahvel.png"
import kaas from "../../../assets/images/kaas.png"
import taldrik from "../../../assets/images/taldrik.png"

export const Wrapper = styled.div`
  z-index: 1;
  padding-bottom: 4rem;
  @media (min-width: 1024px) {
    padding-bottom: 0;
  }
`

export const Background = styled.div`
  background-color: transparent;
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.025;
  z-index: 0;
  left: 0;
  top: 0;
  background-image: url(${shapes});
  background-size: contain;
  @media only screen and (min-width: 1024px) {
    ${props =>
      props.bottom &&
      css`
        top: unset;
        bottom: 0;
      `}
  }
`

export const Fork = styled.div`
  z-index: 1;
  position: absolute;
  right: 10px;
  top: 0;
  width: 145px;
  height: 540px;
  background-image: url(${fork});
  background-size: contain;
  background-repeat: no-repeat;
  @media (min-width: 1024px) {
    right: 100px;
    height: 610px;
    width: 166px;
  }
  @media only screen and (min-width: 1200px) {
    right: 100px;
    height: 80vh;
    width: 235px;
  }
  @media only screen and (min-width: 1300px) {
    right: 120px;
  }
  @media only screen and (min-width: 1400px) {
    right: 150px;
  }
  @media only screen and (min-width: 1500px) {
    right: 200px;
  }
  @media only screen and (min-width: 1600px) {
    right: 250px;
  }
  @media only screen and (min-width: 1700px) {
    right: 300px;
  }
  @media only screen and (min-width: 1800px) {
    right: 350px;
  }
  @media only screen and (min-width: 1900px) {
    right: 400px;
  }
`

export const Landing = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    z-index: 2;
    font-family: "VarelaRound-Regular";
    color: #fff;
    font-weight: 400;
    font-size: 45px;
    line-height: 45px;
    span {
      z-index: 2;
      color: #e2c6a9;
      font-weight: 400;
    }
  }
  h4 {
    z-index: 2;
    font-family: "JosefinSans-Regular";
    font-weight: 400;
    color: #e2c6a9;
    font-size: 19px;
    line-height: 23px;
    max-width: 230px;
  }
  @media (max-width: 374px) {
    h2 {
      font-size: 36px;
      line-height: 40px;
    }
  }
  @media (min-width: 1024px) {
    height: 850px;
    h2 {
      font-size: 64px;
      line-height: 68px;
    }
    h4 {
      font-size: 24px;
      margin-top: 1rem;
      max-width: 800px;
    }
  }
  @media only screen and (min-width: 1200px) {
    h2 {
      font-size: 90px;
      line-height: 90px;
      max-width: 800px;
      span {
        font-size: 90px;
      }
    }
    h4 {
      font-size: 30px;
      margin: 4rem 0;
      line-height: 35px;
      max-width: 470px;
    }
  }
`

export const SocialMedia = styled.div`
  display: none;
  z-index: 2;
  a {
    display: block;
    text-decoration: none;
    margin-bottom: 1rem;
  }
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    justify-content: center;
    a {
      display: flex;
      justify-content: center;
    }
    img {
      margin: 0;
    }
  }
  @media (min-width: 1200px) {
    right: 100px;
    a {
      margin-bottom: 1.5rem;
    }
  }
  @media (min-width: 1400px) {
    right: 150px;
  }
  @media (min-width: 1600px) {
    right: 200px;
  }
  @media (min-width: 1700px) {
    right: 250px;
  }
  @media (min-width: 1800px) {
    right: 300px;
  }
`

export const Services = styled.div`
  position: relative;
  padding: 2rem 0;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }
`

export const Introduction = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 0 7rem 0;
  z-index: 2;
  &:before {
    content: "";
    position: absolute;
    left: 20px;
    top: 0;
    height: 150px;
    width: 145px;
    background-image: url(${kaas});
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 0;
  }
  &:after {
    content: "";
    position: absolute;
    right: 20px;
    bottom: 0;
    height: 96px;
    width: 158px;
    background-image: url(${taldrik});
    background-size: contain;
    background-repeat: no-repeat;
  }
  @media only screen and (min-width: 1024px) {
    padding-top: 150px;
    padding-bottom: 200px;
    &:before {
      left: 20px;
      top: 0;
      height: 305px;
      width: 220px;
    }
    &:after {
      right: 20px;
      bottom: 0;
      height: 180px;
      width: 305px;
    }
  }
  @media only screen and (min-width: 1300px) {
    &:before {
      content: "";
      position: absolute;
      left: 30px;
      height: 305px;
      width: 230px;
    }
    &:after {
      content: "";
      position: absolute;
      right: 20px;
      height: 200px;
      width: 340px;
    }
  }
  @media only screen and (min-width: 1400px) {
    &:before {
      content: "";
      position: absolute;
      left: 30px;
      height: 305px;
      width: 240px;
    }
    &:after {
      content: "";
      position: absolute;
      right: 20px;
      height: 200px;
      width: 340px;
    }
  }
  @media only screen and (min-width: 1500px) {
    &:before {
      content: "";
      position: absolute;
      left: 50px;
      height: 320px;
      width: 250px;
    }
    &:after {
      content: "";
      position: absolute;
      right: 40px;
      height: 213px;
      width: 352px;
    }
  }
  @media only screen and (min-width: 1600px) {
    &:before {
      content: "";
      position: absolute;
      left: 40px;
      height: 305px;
      width: 265px;
    }
    &:after {
      content: "";
      position: absolute;
      right: 20px;
      height: 210px;
      width: 350px;
    }
  }
  @media only screen and (min-width: 1700px) {
    &:before {
      content: "";
      position: absolute;
      left: 100px;
      height: 305px;
      width: 295px;
    }
    &:after {
      content: "";
      position: absolute;
      right: 30px;
      height: 248px;
      width: 411px;
    }
  }
`

export const IntroductionText = styled.div`
  h2 {
    color: #fff;
    text-transform: uppercase;
    font-family: "JosefinSans-Bold";
    font-weight: 700;
    text-align: center;
    font-size: 34px;
    margin-bottom: 3rem;
    line-height: 38px;
  }
  p,
  button {
    color: #fff;
    font-family: "JosefinSans-Regular";
    font-weight: 400;
    line-height: 23px;
  }
  button {
    position: relative;
    cursor: pointer;
    padding: 0;
    background: unset;
    border: unset;
    outline: none;
    @media (min-width: 1024px) {
      transition: 0.25s ease;
      padding-bottom: 0.25rem;
      &:after {
        transition: 0.25s ease;
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 1.5px;
        background: #fff;
      }
      &:hover {
        &:after {
          width: 100%;
        }
      }
    }
  }
  @media only screen and (min-width: 1024px) {
    padding-left: 10rem;
    h2 {
      width: min-content;
      line-height: 107px;
      font-size: 97px;
      text-align: left;
      margin-bottom: 1.5rem;
    }
    p {
      font-size: 24px;
      line-height: 35px;
      max-width: 850px;
    }
  }
  @media only screen and (min-width: 1400px) {
    padding-left: 12rem;
  }
`

export const CardsWrapper = styled.div`
  background-color: #fff;
  padding: 3rem 0;
  @media only screen and (min-width: 1200px) {
    padding: 10rem 0;
  }
`

export const CardsInnerWrapper = styled.div`
  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
  }
`

export const TeamWrapper = styled.div`
  position: relative;
  padding: 1rem 0;
  @media only screen and (min-width: 1024px) {
    padding: 8rem 0;
  }
`
