import styled, { keyframes } from "styled-components"
import shape from "../../../assets/svg/service-shape.svg"

export const Wrapper = styled.div`
  @keyframes rotation {
    0% {
      transform: translateX(-50%) rotate(0deg) scale(1);
    }
    30% {
      transform: translateX(-50%) rotate(90deg) scale(0.7);
    }
    70% {
      transform: translateX(-50%) rotate(90deg) scale(0.7);
    }
    100% {
      transform: translateX(-50%) rotate(0) scale(1);
    }
    }
  }
  position: relative;
  margin: 3rem auto;
  border-radius: 0.5rem;
  width: 250px;
  height: 375px;
  img {
    margin: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
  }
  @media only screen and (min-width: 1200px) {
    margin: unset;
    height: 580px;
    width: 350px;
    &:after {
      content: "";
      position: absolute;
      height: 143px;
      width: 143px;
      bottom: -71.5px;
      left: 50%;
      transform: translateX(-50%);
      background-image: url(${shape});
      background-size: contain;
    }
    &:hover {
      &:after {
        animation-name: rotation;
        animation-duration: .75s;
      }
    }
  }
  @media only screen and (min-width: 1400px) {
    height: 610px;
    width: 400px;
  }
`

export const Title = styled.div`
  padding: 3rem 0;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(26, 31, 41, 0.78);
  width: 100%;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  h3 {
    font-family: "JosefinSans-Regular";
    color: #fff;
    font-weight: 400;
    width: 220px;
    font-size: 26px;
    margin: auto;
    line-height: 27px;
  }
  @media only screen and (min-width: 1200px) {
    width: 100%;
    height: 305px;
    display: flex;
    align-items: center;
    h3 {
      font-size: 34px;
      line-height: 38px;
      max-width: 90%;
      width: 100%;
    }
  }
`
