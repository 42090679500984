import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Main from "../components/Pages/Main"

export default function Home({ data }) {
  return (
    <Layout background="dark">
      <SEO
        title="Avaleht"
        description="Menüü loomine. Peakoka teenus. Restoranide nõustamine. Catering."
      />
      <Main />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allWpPost(sort: { fields: [date] }) {
      nodes {
        title
        excerpt
        slug
      }
    }
  }
`
